import { userService } from "../services"
import history from '../history';


export const doLogout=(data)=> dispatch => {
    localStorage.setItem("ud", btoa(''));
    dispatch({
        type: 'LOGIN_USER',
        data: {}
    });
    if(document.location.pathname !== "/") {
        history.push('/');
    }

};
export const doSignup=(data)=> dispatch => {//alert('doSocialSignup')
    let user = userService.userSignUp(data);
    dispatch({
        type: 'ERROR_MESSAGE',
        status: false,
        element: '',
        message: ''
    });
    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'SIGNUP'
    });
    user.then(res => {
       // console.log(res)
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'SIGNUP'
        });
        if(res.data.results.success === true) {

            if(data.logintype === "google" || data.logintype === "facebook" || data.logintype === "twitter") {
                localStorage.setItem("ud", btoa(JSON.stringify(res.data.results.data)));
                dispatch({
                    type: 'LOGIN_USER',
                    data: res.data.results.data
                });


            }
        } else {
            dispatch({
                type: 'ERROR_MESSAGE',
                status: true,
                element: 'SIGNUP',
                message: res.data.results.message
            });
        }
    });
};

export const userLogin=(params)=> dispatch => {
    let user = userService.userLogin(params);
    //console.log(user);
    dispatch({
        type: 'ERROR_MESSAGE',
        status: false,
        element: '',
        message: ''
    });
    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'LOGIN_USER'
    });
    user.then(res => {
        //console.log(res)
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'LOGIN'
        });
        if(res && res.code && res.code === 401) {

            dispatch(expireToken(userLogin, params));
        } else {
            if (!res || !res.data) {
                dispatch({
                    type: 'ERROR_MESSAGE',
                    status: true,
                    element: 'NETWORK',
                    message: 'Network failure. Please try again'
                });
                return;
            }
            let result = res.data.results;
            if (result.success === true) {
                let userData = result.data;
                if(!localStorage.getItem('_oId') || localStorage.getItem('_oId') === '') {
                    localStorage.setItem('plan', '');
                }
                if (userData.email) {
                    userData['media'] = 'internal';
                    userData['password'] = params['password'];
                    //userData['userId'] = userData['subscriber_ id'];
                    if (userData['user_category'] === "Bsynapse User" && !userData['login type']) {
                        userData['login type'] = "internal";
                    }
                    localStorage.setItem("ud", btoa(JSON.stringify(userData)));


                    dispatch({
                        type: 'LOGIN_USER',
                        data: userData
                    });
                    if (document.location.pathname === "/sign-in" && userData['user_category'] === "Bsynapse User") {
                        history.push('/subscribe');
                    } else if (document.location.pathname === "/sign-in") {
                        history.push('/');
                    }
                } else {
                    localStorage.setItem("ud", btoa(''));
                    dispatch({
                        type: 'ERROR_MESSAGE',
                        status: true,
                        element: 'LOGIN',
                        message: result.message
                    })
                }

            } else {

                dispatch({
                    type: 'ERROR_MESSAGE',
                    status: true,
                    element: 'LOGIN',
                    message: result.message
                })
            }
        }
    });
};
export const doSocialLogin=(data, media)=> dispatch => {
    //console.log(data);return;
    if(data.email) {
        let user = userService.socialUserLogin(data);

        dispatch({
            type: 'ERROR_MESSAGE',
            status: false,
            element: '',
            message: ''
        });
        dispatch({
            type: 'UPDATE_LOADER',
            status: true,
            element: 'LOGIN_USER'
        });
        user.then(res => {
            dispatch({
                type: 'UPDATE_LOADER',
                status: false,
                element: 'LOGIN'
            });
            if(res && res.code && res.code === 401) {

                dispatch(expireToken(doSocialLogin, data, media));
            } else {
                let result = res && res.data ? res.data.results : {};

                if (result && result.success === true) {
                    let userData = result.data;
                    userData['media'] = media;
                    if(!localStorage.getItem('_oId') || localStorage.getItem('_oId') === '') {
                        localStorage.setItem('plan', '');
                    }
                    if (userData.email) {
                        localStorage.setItem("ud", btoa(JSON.stringify(userData)));
                        dispatch({
                            type: 'LOGIN_USER',
                            data: userData
                        });
                        if (document.location.pathname === "/sign-in") {
                            history.push('/');
                        }
                    } else {
                        localStorage.setItem("ud", btoa(''));
                        dispatch({
                            type: 'ERROR_MESSAGE',
                            status: true,
                            element: 'LOGIN',
                            message: result.message
                        })
                    }

                } else {
                    localStorage.setItem("ud", btoa(''));
                    window.location.href = '/sign-in';
                    dispatch({
                        type: 'ERROR_MESSAGE',
                        status: true,
                        element: 'LOGIN',
                        message: result.message || 'Network Failure, Please try again'
                    })
                }
            }
        });
    }
};
export const updateProfile=(params)=> dispatch => {
    let user = userService.updateProfile(params);

    dispatch({
        type: 'ERROR_MESSAGE',
        status: false,
        element: '',
        message: ''
    });
    dispatch({
        type: 'UPDATE_PROFILE',
        success: false
    });
    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'UPDATE_PROFILE'
    });
    user.then(res => {

        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'UPDATE_PROFILE'
        });
        let result = res.data ? res.data.results : {};
        if(result.success === true) {


            localStorage.setItem("ud", btoa(JSON.stringify(params)));
            dispatch({
                type: 'UPDATE_PROFILE',
                success: true
            });


        } else {

            dispatch({
                type: 'ERROR_MESSAGE',
                status: true,
                element: 'UPDATE_PROFILE',
                message: result.message || 'Network Failure, Please try again'
            })
        }
    });
};
export const doLogin=(data)=> dispatch => {
    dispatch({
        type: 'LOGIN_USER',
        data: data
    });


};
export const findStories=(data, sections)=> dispatch => {
    //console.log(sections)
    if(data.dataFrom !== 'loadMore') {
        dispatch({
            type: 'STORIES',
            data: []
        });
    }
    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'SIGNUP'
    });
    let stories = userService.findStories(data);
    stories.then(res => {
        if(res && res.code && res.code === 401) {
            /*localStorage.setItem("ud", btoa(''));
            dispatch({
                type: 'LOGIN_USER',
                data: {}
            });
            history.push('/sign-in');
            return;*/
            dispatch(expireToken(findStories, data, sections));
        } else {
            dispatch({
                type: 'UPDATE_LOADER',
                status: false,
                element: 'LOGIN'
            });

            let records = [];
            let count = 0;
            //console.log(sections);
            let sectionName = {
                "Market News": "market",
                "Investment Insights": "invest",
                "Special Coverage": "special",
                "Hedge Highlights": "hedge",
            }
            if (res && res.data) {
                //console.log(res.data)
                //console.log(sections)
                if (data.allstories === false || (data.search && data.search !== '')) {
                    //console.log('1111')
                    records = records.concat(res.data['all']);
                } else { //console.log(data.section)
                    if ((!data.section || data.section === '') && !data.category && !data.pdate) {

                        sections.map((sec, secIndx) =>
                            records = records.concat(res.data[sectionName[sec.text_content_sections[0]]].slice(0, sec.story_count_in_a_home_page_loop[0]))
                        )
                        sections.map((sec, secIndx) =>
                                //console.log(res.data[sectionName[sec.text_content_sections[0]]])
                                //console.log(sec.story_count_in_a_home_page_loop[0])
                                //console.log(sec.story_count_in_a_home_page_loop[0])
                                //console.log(res.data[sectionName[sec.text_content_sections[0]]].slice(sec.story_count_in_a_home_page_loop[0], parseInt(sec.story_count_in_a_home_page_loop[0]) + parseInt(sec.story_count_in_a_home_page_loop[0])))
                                records = records.concat(res.data[sectionName[sec.text_content_sections[0]]].slice(sec.story_count_in_a_home_page_loop[0], parseInt(sec.story_count_in_a_home_page_loop[0]) + parseInt(sec.story_count_in_a_home_page_loop[0])))
                            //console.log(records)
                        )
                        /*records = records.concat(res.data['market'].slice(0, 5));
                        records = records.concat(res.data['invest'].slice(0, 1));
                        records = records.concat(res.data['special'].slice(0, 2));
                        records = records.concat(res.data['hedge'].slice(0, 2));

                        records = records.concat(res.data['market'].slice(5, 10));
                        records = records.concat(res.data['invest'].slice(1, 2));
                        records = records.concat(res.data['special'].slice(2, 4));
                        records = records.concat(res.data['hedge'].slice(2, 4));*/
                        count = res.data['counts']['market'] + res.data['counts']['invest'] + res.data['counts']['special'] + res.data['counts']['hedge'];
                    } else {

                        records = records.concat(res.data['all']);
                        /*records = records.concat(res.data['invest']);
                        records = records.concat(res.data['special']);
                        records = records.concat(res.data['hedge']);*/
                        count = res.data['counts']
                    }
                }

                dispatch({
                    type: 'STORIES',
                    data: records,
                    total: count,
                    dataFrom: data.dataFrom,
                    page: data.page || 1
                });
            } else {
                dispatch({
                    type: 'ERROR_MESSAGE',
                    status: true,
                    element: 'STORIES',
                    message: res ? res.message : 'Network Failure',

                })
            }
        }
    });
};
export const findSections=(data)=> dispatch => {
    dispatch({
        type: 'SECTIONS',
        data: []
    });
    let sections = userService.findSections(data);
    sections.then(res => {
        if(res && res.code && res.code === 401) {

            dispatch(expireToken(findSections, data));
        } else {
            if (!res || !res.data) {
                dispatch({
                    type: 'ERROR_MESSAGE',
                    status: true,
                    element: 'NETWORK',
                    message: 'Network failure. Please try again'
                });
                return;
            }
            let result = res.data.results;
            if (result.success === true) {
                let result = res.data.results;
                let info = [];
                let data = [];

                data = result.data;
                data.forEach((d, indx) => {

                    info[indx] = {};

                    d.collectedform.forEach((c, cIndx) => {
                        let val = [];
                        c.value.forEach((v, vIndx) => {
                            val.push(v.value);
                        })
                        info[indx][c.label] = val;
                    });
                });
                dispatch({
                    type: 'SECTIONS',
                    data: info
                });
            }
        }
    });
};
export const findCategories=(data)=> dispatch => {
    dispatch({
        type: 'CATEGORIES',
        data: []
    });
    let sections = userService.findCategories(data);
    sections.then(res => {
        if(res && res.code && res.code === 401) {
            dispatch(expireToken(findCategories, data));
        } else {
            if (!res || !res.data) {
                dispatch({
                    type: 'ERROR_MESSAGE',
                    status: true,
                    element: 'NETWORK',
                    message: 'Network failure. Please try again'
                });
                return;
            }
            let result = res.data.results;
            if (result.success === true) {
                let result = res.data.results;
                let info = [];
                let data = [];

                data = result.data;

                data.forEach((d, indx) => {
                    info[indx] = {};

                    d.collectedform.forEach((c, cIndx) => {


                        info[indx][c.label] = c.value[0].value;
                    });
                });
                dispatch({
                    type: 'CATEGORIES',
                    data: info
                });
            }
        }
    });
};
export const findSubscriptionPlans=(data)=> dispatch => {
    dispatch({
        type: 'SUBSCRIPTION_PLANS',
        data: []
    });
    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'SUBSCRIPTION_PLANS'
    });
    let sections = userService.findSubscriptionPlans(data);

    sections.then(res => {
        if(res && res.code && res.code === 401) {
            dispatch(expireToken(findSubscriptionPlans, data));
        } else {
            dispatch({
                type: 'UPDATE_LOADER',
                status: false,
                element: 'SUBSCRIPTION_PLANS'
            });
            if (!res || !res.data) {
                dispatch({
                    type: 'ERROR_MESSAGE',
                    status: true,
                    element: 'NETWORK',
                    message: 'Network failure. Please try again'
                });
                return;
            }
            let result = res.data.results;
            if (result.success === true) {
                let result = res.data.results;
                let info = [];
                let data = [];

                data = result.data;

                data.forEach((d, indx) => {
                    info[indx] = {id: d.id};

                    d.collectedform.forEach((c, cIndx) => {


                        info[indx][c.label] = c.value[0].value;
                    });
                });
                dispatch({
                    type: 'SUBSCRIPTION_PLANS',
                    data: info
                });
            }
        }
    });
};
export const findStoryDetails=(data)=> dispatch => {
    dispatch({
        type: 'STORY_DETAILS',
        data: []
    });
    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'STORY_DETAILS'
    });
    let storyDetails = userService.findStoryDetails(data);
    storyDetails.then(res => {
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'STORY_DETAILS'
        });
        let result = res.data.results;
        if (result.success === true) {
            let info = [];
            let result = res.data.results;
            let data = result.data;

            data.forEach((d, indx) => {
                info[indx] = {};
                let val = [];
                d.values.forEach((v, indx) => {
                    if(d.type === 'attachment') {
                        val.push(v.uniqvalue)
                    } else {
                        val.push(v.value)
                    }
                });
                info[d.label] = val;
                info['_id'] = result.id;

            });
            info.flag = result.flag;
            //console.log(info)
            dispatch({
                type: 'STORY_DETAILS',
                data: info
            });
        }
    });
};

export const findPageContents=(data)=> dispatch => {
    dispatch({
        type: 'PAGE_CONTENTS',
        data: []
    });
    let pages = userService.findPageContents(data);
    pages.then(res => {
        if(res && res.code && res.code === 401) {
            dispatch(expireToken(findPageContents, data));
        } else {
            if (!res || !res.data) {
                dispatch({
                    type: 'ERROR_MESSAGE',
                    status: true,
                    element: 'NETWORK',
                    message: 'Network failure. Please try again'
                });
                return;
            }
            let result = res.data.results;
            if (result.success === true) {
                let info = [];
                let result = res.data.results;
                let data = result.data;
                let pages = [];

                data.forEach((d, indx) => {
                    info[indx] = {};
                    d.collectedform.forEach((c, cIndx) => {
                        info[indx][c.label] = c.value[0].value;
                    });
                    pages[info[indx]['text_content_type']] = info[indx]['html-edtr-copy']
                });
                dispatch({
                    type: 'PAGE_CONTENTS',
                    data: pages
                });
            }
        }
    });
};
export const findAds=(data)=> dispatch => {
    dispatch({
        type: 'ADS',
        data: []
    });
    let pages = userService.findAds(data);
    pages.then(res => {
        if(res && res.code && res.code === 401) {
            dispatch(expireToken(findAds, data));
        } else {
            if (!res || !res.data) {
                dispatch({
                    type: 'ERROR_MESSAGE',
                    status: true,
                    element: 'NETWORK',
                    message: 'Network failure. Please try again'
                });
                return;
            }
            let result = res.data.results;
            if (result.success === true) {
                let info = [];
                let result = res.data.results;
                let data = result.data;
                let box = [];
                ;
                let solo = []
                data.forEach((d, indx) => {
                    info[indx] = {};
                    d.collectedform.forEach((c, cIndx) => {
                        if (c.type === 'attachment') {
                            info[indx][c.label] = c.value[0] ? c.value[0].uniqvalue : '';
                        } else {
                            info[indx][c.label] = c.value[0] ? c.value[0].value : '';
                        }
                    });
                    if (info[indx]['ad_position'] === "Full Story - Solo Position") {
                        solo.push(info[indx]);
                    } else {
                        let boxIndx = parseInt(info[indx]['ad_position'].replace('Box Listing - Position', ''));
                        box[boxIndx - 1] = info[indx];
                        //box.push(info[indx]);
                    }
                });

                dispatch({
                    type: 'ADS',
                    solo: solo,
                    box: box
                });
            }
        }
    });
};
export const saveContactForm=(data)=> dispatch => {
    dispatch({
        type: 'CONTACT_FORM',
        data: []
    });
    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'CONTACT_FORM'
    });
    let pages = userService.saveContactForm(data);
    pages.then(res => {
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'CONTACT_FORM'
        });
        let result = res.data.results;
        if (result.success === true) {
            dispatch({
                type: 'CONTACT_FORM',
                message: result.message
            });
        } else {
            dispatch({
                type: 'CONTACT_FORM',
                message: result.message
            });
        }
    });
};
export const findSocialLinks=(data)=> dispatch => {
    dispatch({
        type: 'SOCIAL_LINKS',
        data: []
    });

    let sections = userService.findSocialLinks(data);

    sections.then(res => {
        if(res && res.code && res.code === 401) {
            dispatch(expireToken(findSocialLinks, data));
        } else {
            if (!res || !res.data) {
                dispatch({
                    type: 'ERROR_MESSAGE',
                    status: true,
                    element: 'NETWORK',
                    message: 'Network failure. Please try again'
                });
                return;
            }
            let result = res.data.results;
            if (result.success === true) {
                let result = res.data.results;
                let info = [];
                let data = [];
                let social = {};
                data = result.data;

                data.forEach((d, indx) => {
                    info[indx] = {};

                    d.collectedform.forEach((c, cIndx) => {


                        info[indx][c.label] = c.value[0].value;
                    });
                    social[info[indx]['text_digital_asset']] = info[indx]['text_asset_url'];
                });
                dispatch({
                    type: 'SOCIAL_LINKS',
                    data: social
                });
            }
        }
    });
};
export const sendOTP=(data)=> dispatch => {

    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'SEND_OTP'
    });
    let otp = userService.sendOTP(data);
    otp.then(res => {
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'SEND_OTP'
        });
        //console.log(res.data)
        let result = res.data;
        if (result.success === true) {
            dispatch({
                type: 'SEND_OTP',
                success: result.success,
                message: result.message
            });
        } else {
            dispatch({
                type: 'SEND_OTP',
                success: result.success,
                message: result.message
            });
        }
    });
};
export const verifyOTP=(data)=> dispatch => {

    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'VERIFY_OTP'
    });
    let otp = userService.verifyOTP(data);
    otp.then(res => {
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'VERIFY_OTP'
        });
        //console.log(res.data)
        let result = res.data;
        if (result.success === true) {
            dispatch({
                type: 'VERIFY_OTP',
                success: result.success,
                message: result.message
            });
        } else {
            dispatch({
                type: 'VERIFY_OTP',
                success: result.success,
                message: result.message
            });
        }
    });
};
export const addSubscriber=(data)=> dispatch => {

    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'ADD_SUBSCRIBER'
    });
    let subscriber = userService.addSubscriber(data);
    userService.transactionsAPILog({
        name: data.name,
        orderId: data.orderId,
        action: 'AddSubscriber'
    });
    subscriber.then(res => {
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'ADD_SUBSCRIBER'
        });
        //console.log(res.data)
        let result = res.data.results;
        if (result.success === true) {
            //userService.savePaymentSuccess(data);
            dispatch({
                type: 'ADD_SUBSCRIBER',
                success: true,
                data: result.data,
                message: result.message
            });
            if(!result.data.password) {
                result.data.password = (data.password);
            }
            localStorage.setItem('ud', btoa(JSON.stringify(result.data)))
            localStorage.setItem('_pId', window.btoa(data.paymentId));
            dispatch({
                type: 'LOGIN_USER',
                data: result.data
            });
            window.location = '/my-account';
            //history.push('/my-account');
            localStorage.setItem('plan', '');

        } else {
            dispatch({
                type: 'ADD_SUBSCRIBER',
                success: false,
                data: result.data,
                message: result.message
            });

        }
    });
};
export const renewSubscriber=(data)=> dispatch => {

    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'RENEW_SUBSCRIBER'
    });
    let subscriber = userService.renewSubscriber(data);
    userService.transactionsAPILog({
        name: data.name,
        orderId: data.orderId,
        action: 'RenewSubscriber'
    });
    subscriber.then(res => {
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'RENEW_SUBSCRIBER'
        });
        //console.log(res.data)
        let result = res.data.results;
        if (result.success === true) {
            //userService.savePaymentSuccess(data);
            dispatch({
                type: 'RENEW_SUBSCRIBER',
                success: true,
                data: result.data,
                message: result.message
            });
            if(!result.data.password) {
                result.data.password = (data.password);
            }
            localStorage.setItem('ud', btoa(JSON.stringify(result.data)))
            dispatch({
                type: 'LOGIN_USER',
                data: result.data
            });
            window.location = '/my-account';
            //history.push('/my-account');
            localStorage.setItem('plan', '');

        } else {
            dispatch({
                type: 'RENEW_SUBSCRIBER',
                success: false,
                data: result.data,
                message: result.message
            });

        }
    });
};
export const ohariPaymentInfo=(data)=> dispatch => {

    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'RENEW_SUBSCRIBER'
    });
    let subscriber = userService.ohariPaymentInfo(data);
    userService.transactionsAPILog({
        name: data.name,
        orderId: data.orderId,
        action: 'OhariPaymentInfo'
    });
    subscriber.then(res => {
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'RENEW_SUBSCRIBER'
        });
        console.log(res)
        let result = res.data;
        console.log(result.data)
        console.log('OhariPaymentInfo status: ' + result.success)
        if (result.success === true) {
            console.log('OhariPaymentInfo success')
            //console.log(result.data.method)
            localStorage.setItem('_pId', '');
            localStorage.setItem('_pMtd', result.data.method);
            dispatch({
                type: 'PAYMENT_INFO',
                data: result.data
            });
        } else {
            console.log('OhariPaymentInfo failed')
            dispatch({
                type: 'PAYMENT_INFO',
                data: result.data
            });

        }

    });
};
export const saveSignupPayment=(data, plan, type)=> dispatch => {
    let subscriber = userService.saveSignupPayment(data, plan, type);
    let paymentTransactionLog = userService.paymentTransactionLog({
        orderId: data.orderId || '',
        paymentId: data.paymentId || '',
        status: data.status ? data.status.toLowerCase() : ''
    });
    userService.transactionsAPILog({
        name: data.name,
        orderId: data.orderId,
        action: 'PaymentTransactionLog'
    });
    //console.log('updatelog');
    let log = userService.updateLog({
        logId: localStorage.getItem('logId'),
        paymentId: data.paymentId,
        status: data.status ? data.status.toLowerCase() : ''
    });
    userService.transactionsAPILog({
        name: data.name,
        orderId: data.orderId,
        action: 'UpdateRegistrationLog'
    });
    log.then(res => {
        localStorage.setItem('logId', '');
        localStorage.setItem('_pMtd', '');
    });
};
export const checkSubscriberExists=(data)=> dispatch => {

    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'EXISTS_SUBSCRIBER'
    });
    dispatch({
        type: 'EXISTS_SUBSCRIBER',
        success: false,
        data: [],
        message: ''
    });
    let subscriber = userService.checksubscriberexists(data);
    subscriber.then(res => {
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'EXISTS_SUBSCRIBER'
        });

        let result = res.data.results;

        if (result.success === true) {
            dispatch({
                type: 'EXISTS_SUBSCRIBER',
                success: true,
                data: data,
                message: result.message
            });


        } else {
            dispatch({
                type: 'EXISTS_SUBSCRIBER',
                success: false,
                message: result.message
            });

        }
    });
};
export const addBookmarks=(data)=> dispatch => {
    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'ADD_BOOKMARK'
    });

    dispatch({
        type: 'ADD_BOOKMARK',
        success: false
    });
    let subscriber = userService.addBookMarks(data);
    subscriber.then(res => {

        let result = res.data.results;

        if (result.success === true) {
            dispatch({
                type: 'ADD_BOOKMARK',
                success: true
            });
            setTimeout(
                function () {
                    dispatch(
                        findBookmarks({
                            userId: data.userId
                        })
                    );
                }, 1000)
        } else {
            dispatch({
                type: 'ADD_BOOKMARK',
                success: false
            });

        }
    });
};
export const deleteBookmarks=(data)=> dispatch => {


    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'DELETE_BOOKMARK'
    });
    let subscriber = userService.deleteBookmarks(data);
    subscriber.then(res => {
//console.log(res)
        let result = res.data;

        if (result.success === true) {
            dispatch({
                type: 'DELETE_BOOKMARK',
                success: true
            });
            setTimeout(
                function () {
                    dispatch(
                        findBookmarks({
                            userId: data.userId
                        })
                    );
                }, 1000)
        } else {
            dispatch({
                type: 'DELETE_BOOKMARK',
                success: false
            });

        }
    });
};
export const findBookmarks=(data)=> dispatch => {

    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'BOOKMARKS'
    });
    let bookmarks = userService.findBookmarks(data);

    bookmarks.then(res => {
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'BOOKMARKS'
        });
        if(res && res.code && res.code === 401) {
            dispatch(expireToken(findBookmarks, data));
        } else {
            if (!res || !res.data) {
                dispatch({
                    type: 'ERROR_MESSAGE',
                    status: true,
                    element: 'NETWORK',
                    message: 'Network failure. Please try again'
                });
                return;
            }
            let result = res.data.results;
            if (result.success === true) {
                let result = res.data.results;
                let info = [];
                let data = [];

                data = result.data;

                data.forEach((d, indx) => {
                    info[indx] = {id: d.id};

                    d.collectedform.forEach((c, cIndx) => {
                        info[indx][c.label] = c.value[0].value;
                    });
                });
                dispatch({
                    type: 'BOOKMARKS',
                    data: info
                });
            } else {
                dispatch({
                    type: 'BOOKMARKS',
                    data: []
                });
            }
        }
    });
};
export const findBookmarkedStories=(data)=> dispatch => {
    dispatch({
        type: 'BOOKMARK_STORIES',
        data: []
    });
    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'BOOKMARK_STORIES'
    });
    if(data.bookmarkIds.length > 0) {
        let bookmarks = userService.findBookmarkedStories(data);

        bookmarks.then(res => {
            dispatch({
                type: 'UPDATE_LOADER',
                status: false,
                element: 'BOOKMARK_STORIES'
            });
            let result = res.data.results;
            if (result.success === true) {
                let result = res.data.results;
                let info = [];
                let data = [];

                data = result.data;

                data.forEach((d, indx) => {
                    info[indx] = {id: d.id};

                    d.collectedform.forEach((c, cIndx) => {

                        if (c.type === 'attachment') {
                            info[indx][c.label] = c.value[0] ? c.value[0].uniqvalue : '';
                        } else {
                            info[indx][c.label] = c.value[0] ? c.value[0].value : '';
                        }
                    });
                });
                //console.log(info)
                dispatch({
                    type: 'BOOKMARK_STORIES',
                    data: info,
                    dataFrom: data.dataFrom
                });
            }
        });
    } else {
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'BOOKMARK_STORIES'
        });
    }
};
export const forgotPassword=(data)=> dispatch => {
    dispatch({
        type: 'FORGOT_PASSWORD',
        data: []
    });
    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'FORGOT_PASSWORD'
    });
    let reset = userService.forgotPassword(data);

    reset.then(res => {
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'FORGOT_PASSWORD'
        });
        let result = res.data.results;
        //console.log(result)
        if (result.flag === 1) {
            dispatch({
                type: 'FORGOT_PASSWORD',
                message: 'We have sent a reset-password link to your registered email.'
            });
        } else if (result.flag === 0) {
            dispatch({
                type: 'FORGOT_PASSWORD',
                message: 'Invalid email, Please enter your registered email.'
            });
        } else if (result.flag === 2) {
            dispatch({
                type: 'FORGOT_PASSWORD',
                message: 'To register your email id please contact Hedge.'
            });
        } else if(result.flag === 'google'){
            dispatch({
                type: 'FORGOT_PASSWORD',
                message: 'This email id is already registered with google signup. Please log in using a Google account.'
            });
        } else if(result.flag === 'facebook'){
            dispatch({
                type: 'FORGOT_PASSWORD',
                message: 'This email id is already registered with Facebook signup. Please log in using a Facebook account.'
            });
        }
    });
};
export const resetPassword=(data)=> dispatch => {
    dispatch({
        type: 'RESET_PASSWORD',
        data: []
    });
    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'RESET_PASSWORD'
    });
    let reset = userService.resetPassword(data);

    reset.then(res => {
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'RESET_PASSWORD'
        });
        let result = res.data.results;
        //console.log(result)
        if (result.success === true) {
            dispatch({
                type: 'RESET_PASSWORD',
                message: result.message
            });
        } else {
            dispatch({
                type: 'RESET_PASSWORD',
                message: result.message
            });
        }
    });
};
export const placeOrder=(data, params, selectedPlan, refer)=> dispatch => {
    dispatch({
        type: 'PLACE_ORDER',
        data: []
    });
    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'PLACE_ORDER'
    });
    let reset = userService.placeOrder(data);
    localStorage.setItem('cb', '');
    reset.then(res => {

        //console.log(res)
        let result = res ? res.data : {};
        //console.log(params)
        //console.log(result)
        //console.log(selectedPlan)
        //return;
        if (res && result.success === true) {
            userService.transactionsAPILog({
                name: params.name,
                orderId: result.id,
                action: 'RegistrationLog'
            });
            let log = userService.logRegister({
                name: params.name,
                email: params.email,
                dob: params.loginType === 'internal' ? params.dob : '',
                mobile: params.mobile,
                profession: params.loginType === 'internal' ? params.profession : '',
                password: params.loginType === 'internal' ? params.password : '',
                loginType: params.loginType,
                selectedPlan: selectedPlan,
                orderId: result.id,
                amount: data.amount/100

            }, refer);

            let logId = '';
            log.then(logres => {

                //console.log('logres');
                //console.log(logres);//return;

                let logresult = logres ? logres.data : {};
                //console.log('logresult');
                //console.log(logresult);
                if (logres && logresult.results && logresult.results.success === true) {
                    let paymentTransactionLog = userService.paymentTransactionLog({
                        orderId: result.id,
                        paymentId: '',
                        status: 'open'
                    });
                    userService.transactionsAPILog({
                        name: params.name,
                        orderId: result.id,
                        action: 'TransactionLog'
                    });
                    logId = logresult.results.data;
                    localStorage.setItem('logId', logId);
                    localStorage.setItem('_oId', window.btoa(result.id));
                    localStorage.setItem('_oAmt', window.btoa(data.amount));
                    localStorage.setItem('_oCry', window.btoa(data.currency));
                    localStorage.setItem('_oRct', window.btoa(data.receipt));
                    localStorage.setItem('flag', '');
                    dispatch({
                        type: 'PLACE_ORDER',
                        id: result.id
                    });
                } else {
                    localStorage.setItem('_oid', '');
                    localStorage.setItem('_oAmt', '');
                    localStorage.setItem('_oCry', '');
                    localStorage.setItem('_oRct', '');
                    dispatch({
                        type: 'UPDATE_LOADER',
                        status: false,
                        element: 'PLACE_ORDER'
                    });
                    dispatch({
                        type: 'ERROR_MESSAGE',
                        status: true,
                        element: 'LOG_ERROR',
                        message: "An error occurred, please try again"
                    });
                }
            });



        } else {
            localStorage.setItem('_oid', '');
            localStorage.setItem('_oAmt', '');
            localStorage.setItem('_oCry', '');
            localStorage.setItem('_oRct', '');
            dispatch({
                type: 'UPDATE_LOADER',
                status: false,
                element: 'PLACE_ORDER'
            });
            dispatch({
                type: 'ERROR_MESSAGE',
                status: true,
                element: 'LOG_ERROR',
                message: "An error occurred, please try again"
            });
        }
    });
};
export const existsTransaction=(data)=> dispatch => {
    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'EXISTS_TRANSACTION'
    });

    dispatch({
        type: 'EXISTS_TRANSACTION',
        status: 'loading'
    });
    let subscriber = userService.existsTransaction(data);
    userService.transactionsAPILog({
        name: data.name,
        orderId: data.orderId,
        action: 'CheckSubscriberTransaction'
    });
    subscriber.then(res => {
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'EXISTS_TRANSACTION'
        });
        let result = res.data.results;

        if (result.success === false) {
            dispatch({
                type: 'EXISTS_TRANSACTION',
                status: true
            });

        } else {
            dispatch({
                type: 'EXISTS_TRANSACTION',
                status: false
            });

        }
    });
};
export const expireToken=(callback, data, p='')=> dispatch => {
    let token = userService.generateToken(data);
    let ud = JSON.parse(atob(localStorage.getItem("ud"))) || {};
    token.then(res => {
        if(res.data.results.success === true) {
            ud.token = res.data.results.data;
            localStorage.setItem("ud", btoa(JSON.stringify(ud)));
            if(p === '') {
                dispatch(callback(data))
            } else {
                dispatch(callback(data, p))
            }
        }
    });
    return;
};
export const clearErrorMessage=()=> dispatch => {
    dispatch({
        type: 'ERROR_MESSAGE',
        status: false,
        element: '',
        message: ""
    });
};
export const transactionsAPILog=(props)=> dispatch => {
    userService.transactionsAPILog({
        name: props.name,
        orderId: props.orderId,
        action: props.action,
        info: props.info
    });
};
export const getOhariDetails=(data)=> dispatch => {
    dispatch({
        type: 'SECTIONS',
        data: []
    });
    dispatch({
        type: 'CATEGORIES',
        data: []
    });
    dispatch({
        type: 'ADS',
        solo: [],
        box: []
    });
    dispatch({
        type: 'PAGE_CONTENTS',
        data: []
    });
    dispatch({
        type: 'SOCIAL_LINKS',
        data: []
    });
    let details = userService.getOhariDetails(data);
    details.then(res => {
        if(res && res.code && res.code === 401) {

            dispatch(expireToken(getOhariDetails, data));
        } else {
            if (!res || !res.data) {
                dispatch({
                    type: 'ERROR_MESSAGE',
                    status: true,
                    element: 'NETWORK',
                    message: 'Network failure. Please try again'
                });
                return;
            }
            let result = res.data.results;
            if (result.success === true) {
                let result = res.data.results;
                let info = [];
                let data = [];

                data = result.data;
                //sections
                let sections = data.sections;
                sections.forEach((d, indx) => {

                    info[indx] = {};

                    d.collectedform.forEach((c, cIndx) => {
                        let val = [];
                        c.value.forEach((v, vIndx) => {
                            val.push(v.value);
                        })
                        info[indx][c.label] = val;
                    });
                });
                dispatch({
                    type: 'SECTIONS',
                    data: info
                });

                //categories
                info = [];
                let categories = data.categories;
                categories.forEach((d, indx) => {
                    info[indx] = {};

                    d.collectedform.forEach((c, cIndx) => {


                        info[indx][c.label] = c.value[0] ? c.value[0].value : '';
                    });
                });
                dispatch({
                    type: 'CATEGORIES',
                    data: info
                });

                //ads
                let box = [];
                let solo = []
                info = [];
                let ads = data.ads;
                ads.forEach((d, indx) => {
                    info[indx] = {};
                    d.collectedform.forEach((c, cIndx) => {
                        if (c.type === 'attachment') {
                            info[indx][c.label] = c.value[0] ? c.value[0].uniqvalue : '';
                        } else {
                            info[indx][c.label] = c.value[0] ? c.value[0].value : '';
                        }
                    });
                    if (info[indx]['ad_position'] === "Full Story - Solo Position") {
                        solo.push(info[indx]);
                    } else {
                        let boxIndx = parseInt(info[indx]['ad_position'].replace('Box Listing - Position', ''));
                        box[boxIndx - 1] = info[indx];
                        //box.push(info[indx]);
                    }
                });

                dispatch({
                    type: 'ADS',
                    solo: solo,
                    box: box
                });

                //pagecontents
                info = [];
                let pages = [];
                let pagecontents = data.pagecontents;
                pagecontents.forEach((d, indx) => {
                    info[indx] = {};
                    d.collectedform.forEach((c, cIndx) => {
                        info[indx][c.label] = c.value[0].value;
                    });
                    pages[info[indx]['text_content_type']] = info[indx]['html-edtr-copy']
                });
                dispatch({
                    type: 'PAGE_CONTENTS',
                    data: pages
                });

                //sociallinks
                let sociallinks = data.sociallinks;
                let social = {};
                info = [];


                sociallinks.forEach((d, indx) => {
                    info[indx] = {};

                    d.collectedform.forEach((c, cIndx) => {


                        info[indx][c.label] = c.value[0].value;
                    });
                    social[info[indx]['text_digital_asset']] = info[indx]['text_asset_url'];
                });
                dispatch({
                    type: 'SOCIAL_LINKS',
                    data: social
                });
            }
        }
    });
};
