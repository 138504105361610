import React, { Component } from 'react';
import { connect } from "react-redux";
import * as actionReducers from "./reducers";
import * as ActionCreators from "./actions";
import Layout from './components/layout/Layout'
import FullPageLayout from './components/layout/FullPageLayout'
import {Router, Route, Switch} from 'react-router-dom'
import Home from './components/pages/Home';
import Bookmarks from './components/pages/Bookmarks';
import Article from './components/pages/Article';
import SignIn from './components/pages/SignIn';
import Subscribe from './components/pages/Subscribe';
import MyAccount from './components/pages/MyAccount';
import Plan from './components/pages/Plan';

import AboutUs from './components/pages/AboutUs';
import ContactUs from './components/pages/ContactUs';
import TermsOfUse from './components/pages/TermsOfUse';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import OhariModal from './components/views/OhariModal';
import LoaderAnimation from "./components/base_levels/LoaderAnimation";
import ForgotPassword from "./components/pages/ForgotPassword";
import ResetPassword from "./components/pages/ResetPassword";
import NotFound from "./components/pages/NotFound";
import history from './history';
import {clearErrorMessage, getOhariDetails} from "./actions";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            section: '',
            selectedPlan: {},
            signupData: {},
            planTab: 'personal',
            searchByFilter: false,
            searchFilterParams: {},
            modal: false,
            currentAd: 0,
            flag: false,
            scroll: '',
            filterTab: false,
            loopIds: [],
            showModal: false,
            modalMessage: '',
            modalHeader: '',
            modalOKaction: '',
            modalShowCancel: false,
            modalCancelAction: '',
            modalSize: 'md',//xl, lg, md, sm
            updated: false,
            t: new Date().getTime(),
            colorscheme: 'light-mode'
        }
        this.getUserData = this.getUserData.bind(this);
        this.changeSection = this.changeSection.bind(this);
        this.filterNews = this.filterNews.bind(this);
        this.selectPlan = this.selectPlan.bind(this);
        this.checkIfUserExists = this.checkIfUserExists.bind(this);
        this.completePersonalDetails = this.completePersonalDetails.bind(this);
        this.signupGoogle = this.signupGoogle.bind(this);
        this.signupFb = this.signupFb.bind(this);
        this.acceptTerms = this.acceptTerms.bind(this);
        this.resetFilterFlag = this.resetFilterFlag.bind(this);
        this.applySearch = this.applySearch.bind(this);
        this.backToNews = this.backToNews.bind(this);
        this.filterToggler = this.filterToggler.bind(this);
        this.setIdsInLoop = this.setIdsInLoop.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.setPlanTab = this.setPlanTab.bind(this);
        this.loginEmail = this.loginEmail.bind(this);
        this.loginGoogle = this.loginGoogle.bind(this);
        this.loginFb = this.loginFb.bind(this);
        this.setDataToRenew = this.setDataToRenew.bind(this);
        this.setDataToUpgrade = this.setDataToUpgrade.bind(this);


    }

    getUserData(id) {
        //console.log(id)
        if (localStorage.getItem("ud") !== null &&
            atob(localStorage.getItem("ud")) !== "") {
            let ud = JSON.parse(atob(localStorage.getItem("ud")));

            if (id === 1) {
                if (ud['login type'] === 'internal' && (!ud['media'] || ud['media'] === '' || ud['media'] === 'internal')) {
                    this.props.userLogin({username: ud.email, password: ud.password});
                }
                if (ud['login type'] !== 'internal' || (ud['media'] && (ud['media'] === 'google' || ud['media'] === 'facebook'))) {
                    this.props.socialLogin({email: ud.email}, ud['media']);
                }
            } else {
                this.setState({
                    loggedIn: true
                })
            }
        } else {
            this.setState({loggedIn: false});
        }

    }

    changeSection(section) {
        if (section === '') {
            let ud = localStorage.getItem("ud") ? JSON.parse(atob(localStorage.getItem("ud"))) : {};
            localStorage.clear();
            sessionStorage.clear();
            localStorage.setItem('allstories', true);
            localStorage.setItem('ud', btoa(JSON.stringify(ud)));
        } else {
            localStorage.setItem('allstories', false);
            localStorage.setItem('search', '');
            localStorage.setItem('section', section);
            localStorage.setItem('searchByFilter', false);
            localStorage.setItem('searchFilterParams', JSON.stringify({}));
        }

        //console.log(document.location.pathname)
        if (document.location.pathname !== "/") {
           // console.log(document.location.pathname.indexOf('/stories/'))
            if (document.location.pathname.indexOf('/stories/') === 0) {
                history.push('/');
            } else {
                window.location = '/';
            }

        }
        this.setState({section: section, flag: !this.state.flag});
        //window.history.pushState(null, '', '/');
        window.mode = "";
    }

    applySearch(search) {

        localStorage.setItem('search', search);
        localStorage.setItem('section', '');
        localStorage.setItem('searchByFilter', false);
        localStorage.setItem('searchFilterParams', JSON.stringify({}));
        if (document.location.pathname !== "/") {
            window.location = '/';
            //history.push('/');
        }
        this.setState({flag: !this.state.flag});

    }

    resetFilterFlag() {
        this.setState({
            searchByFilter: false
        });
    }

    filterNews(filterParams) {
        this.setState({
            searchByFilter: true,
            searchFilterParams: filterParams
        });
        localStorage.setItem('allstories', '');
        localStorage.setItem('section', '');
        localStorage.setItem('search', '');
        localStorage.setItem('searchByFilter', true);
        localStorage.setItem('searchFilterParams', JSON.stringify(filterParams));
        /*this.props.findStories({
            'section' : filterParams.filterSections,
            'category' : filterParams.filterCategories,
            'pdate': filterParams.startDate + ',' + filterParams.endDate
        });*/
        //this.resetFilterFlag();
        //window.location = '/';
        //if(document.location.pathname !== "/") {
        window.location = '/';
        //history.push('/');
        // }
        //window.history.pushState(null, '', '/');
        //window.history.pushState(null, '', '/');
        this.filterToggler(false);
    }

    setIdsInLoop(_ids) {
        _ids = [...new Set(_ids)];
        this.setState({loopIds: _ids});

        localStorage.setItem('loopIds', JSON.stringify(_ids));
    }

    selectPlan(plan) {
        this.setState({
            selectedPlan: plan
        });
    }

    setPlanTab(tab) {
        this.setState({
            planTab: tab
        });
    }

    setDataToRenew(plan, tab, data) {
        this.setState({
            selectedPlan: plan,
            planTab: tab,
            signupData: data
        });
    }

    setDataToUpgrade(plan, tab, data) {
        this.setState({
            selectedPlan: plan,
            planTab: tab,
            signupData: data
        });
    }

    completePersonalDetails(data) {
        this.setState({
            signupData: data,
            planTab: 'terms'
        });
    }

    checkIfUserExists(data) {
        this.props.checkSubscriberExists(data);
    }

    acceptTerms(data) {
        this.setState({
            planTab: 'payment'
        });
    }

    signupGoogle(res) {
        //console.log(res)
        if (res.error !== undefined) {
        } else if (res.profileObj !== undefined) {
            let params = {
                name: res.profileObj.name,
                email: res.profileObj.email,
                password: "",
                confirmpassword: "",
                logintype: "google", //( vission, gmail, facebook ...)
            };

            this.checkIfUserExists(params);
        }
    }

    signupFb(res) {
        if (res.status !== undefined) {
            //console.log("fb-" + res.status);
        } else if (res.error !== undefined) {
        } else if (res.id !== undefined) {
            //console.log(res)
            let params = {
                name: res.first_name + " " + res.last_name,
                email: res.email,
                password: "",
                vissionlogin: "no", //( yes / no )
                logintype: "facebook", //( vission, gmail, facebook ...)
            };

            this.checkIfUserExists(params);
        }
    }

    loginGoogle(res) {
        //console.log(res)

        if (res.error !== undefined) {
        } else if (res.profileObj !== undefined) {
            let params = {
                name: res.profileObj.givenName + " " + res.profileObj.familyName,
                email: res.profileObj.email,
                image: res.profileObj.imageUrl,
                first_name: res.profileObj.givenName,
                last_name: res.profileObj.familyName
            };
            this.props.socialLogin(params, "google");
        }
    }

    loginFb(res) {
        if (res.status !== undefined) {
            //console.log("fb-" + res.status);
        } else if (res.error !== undefined) {
        } else if (res.id !== undefined) {

            let params = {
                name: res.first_name + " " + res.last_name,
                email: res.email,
                image: res.picture.url,
                first_name: res.first_name,
                last_name: res.last_name
            };
            this.props.socialLogin(params, "facebook");
        }
    }

    loginEmail(res) {
        let params = {
            username: res.username,
            password: res.password,
        };
        this.props.userLogin(params);
    }

    backToNews(refer) {
        //window.location = refer;
        if (refer === '/') {
            history.push(refer);
            window.mode = "normal";
            this.setState({updated: !this.state.updated});
            localStorage.setItem('allstories', false);
        } else {
            window.location = refer;
        }
        //window.history.back();
    }

    filterToggler(stat) {
        this.setState({filterTab: stat});
    }

    toggleModal(stat, header = '', message = '', OKaction = '', showCancel = false, cancelAction = '', size = 'md') {
        this.setState({
            showModal: stat,
            modalHeader: header,
            modalMessage: message,
            modalOKaction: OKaction,
            modalShowCancel: showCancel,
            modalCancelAction: cancelAction,
            modalSize: size
        });
        if(stat !== true) {
            this.props.clearErrorMessage();
        }
    }

    componentDidMount() {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
            const newColorScheme = e.matches ? "dark-mode" : "light-mode";
            this.setState({colorscheme: newColorScheme})//alert(newColorScheme)
        });
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            this.setState({colorscheme: 'dark-mode'})
            //alert('dark');
            document.body.classList.add('dark-mode');
        } else {
            this.setState({colorscheme: 'light-mode'})
            //alert('light')
            document.body.classList.add('light-mode');
        }

        if (this.state.loggedIn === false) {
            this.getUserData(1);
        }
        //this.props.findSections();
        //this.props.findCategories();
        //this.props.findAds();
        //this.props.findPageContents();
        //this.props.findSocialLinks();
        this.props.getOhariDetails();
        if (localStorage.getItem('loopIds') === null) {
            localStorage.setItem('loopIds', JSON.stringify(''));
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
//console.log(history.location)
        if (
            (Object.keys(this.props.userAccount.user).length !==
                Object.keys(prevProps.userAccount.user).length)
        ) {
            this.getUserData(2);
        }
        //console.log(this.state.updated)
        //console.log(prevState.updated)
        if (this.state.updated !== prevState.updated) {

            this.setState({t: new Date().getTime()});
        }
        if (
            this.props.userAccount && this.props.userAccount.user && this.props.userAccount.user.userId &&
            (this.props.userAccount.user.userId && this.state.loggedIn === false)
        ) {
            this.setState({
                loggedIn: true
            })
        }
        //console.log(prevState.loggedIn);
        //console.log(this.state.loggedIn);
        if (prevState.loggedIn === false && this.state.loggedIn === true) {
            //this.props.findSections();
            //this.props.findCategories();
            //this.props.findAds();
            //this.props.findPageContents();
            //this.props.findSocialLinks();
            this.props.getOhariDetails();
            this.props.findBookmarks({userId: this.props.userAccount.user.userId});
        }
        if (this.props.loggedIn !== prevProps.loggedIn) {
            this.setState({
                showLogin: false
            });
        }
        if (this.props.errorMessage.status === true && this.props.errorMessage.element === 'NETWORK' && prevProps.errorMessage.element !== 'NETWORK') {
            this.toggleModal(
                true,
                'Network Failure',
                this.props.errorMessage.message,
                this.toggleModal,
                false,
                this.toggleModal,
                'md'
            );
        }
       // console.log(this.props.errorMessage)
        //console.log(prevProps.errorMessage.element)
        //console.log(this.props.errorMessage.status === true)
        //console.log(this.props.errorMessage.element === 'LOG_ERROR');
        //console.log(prevProps.errorMessage.element !== this.props.errorMessage.element);
        if (this.props.errorMessage.status === true && this.props.errorMessage.element === 'LOG_ERROR' && prevProps.errorMessage.element !== this.props.errorMessage.element) {

            this.toggleModal(
                true,
                'Network Failure',
                this.props.errorMessage.message,
                this.toggleModal,
                false,
                this.toggleModal,
                'md'
            );
        }
        /*if(this.props.bookmarks.data && this.props.bookmarks.data !== prevProps.bookmarks.data) {
            this.setState({
                bookmarks: this.props.bookmarks.data,
                bookmarksIds: this.props.bookmarks.data.map(a => a['story-id'])
            });
        }*/

    }

    render() {
        let user = this.props.userAccount.user;
        let sections = this.props.sections.data;
        let categories = this.props.categories.data;
        /*let refer = new URL(window.location.href).searchParams.get("refer");
        console.log(refer);
        let showModal = refer === 'news' ? true : false;*/
       // console.log(window.order)
        //console.log(JSON.parse(window.order))

        return (
            <React.Fragment>


                {window.mode === 'publish' ?
                    <Layout
                        loggedIn={this.state.loggedIn}
                        user={user}
                        sections={sections}
                        categories={categories}
                        changeSection={this.changeSection}
                        filterNews={this.filterNews}
                        ads={this.props.ads}
                        applySearch={this.applySearch}
                        filterToggler={this.filterToggler}
                        filterTab={this.state.filterTab}
                        socialLinks={this.props.socialLinks}
                        userLogout={this.props.userLogout}
                        bookmarks={this.props.bookmarks.data}
                        toggleModal={this.toggleModal}
                        colorscheme={this.state.colorscheme}
                    >
                        <Article
                            searchByFilter={this.state.searchByFilter}
                            ads={this.props.ads}
                            changeSection={this.changeSection}
                            loggedIn={this.state.loggedIn}
                            backToNews={this.backToNews}
                            loopIds={this.state.loopIds}
                            user={this.props.userAccount.user}
                            storyShortDesc={window.storyShortDesc}
                            storyFullDesc={window.storyFullDesc}
                            storyId={window.storyId}
                            colorscheme={this.state.colorscheme}
                        />
                    </Layout>
                    :
                    <React.Fragment>
                        {this.props.loader.status === true && <LoaderAnimation/>}
                        <Router history={history}>
                            <Switch>
                                <Route exact path="/">
                                    <Layout
                                        loggedIn={this.state.loggedIn}
                                        user={user}
                                        sections={sections}
                                        categories={categories}
                                        changeSection={this.changeSection}
                                        filterNews={this.filterNews}
                                        ads={this.props.ads}
                                        applySearch={this.applySearch}
                                        filterToggler={this.filterToggler}
                                        filterTab={this.state.filterTab}
                                        socialLinks={this.props.socialLinks}
                                        userLogout={this.props.userLogout}
                                        bookmarks={this.props.bookmarks.data}
                                        toggleModal={this.toggleModal}
                                        colorscheme={this.state.colorscheme}
                                    >
                                        <Home
                                            flag={this.state.flag}
                                            section={this.state.section}
                                            searchByFilter={this.state.searchByFilter}
                                            searchFilterParams={this.state.searchFilterParams}
                                            resetFilterFlag={this.resetFilterFlag}
                                            loggedIn={this.state.loggedIn}
                                            scroll={this.state.scroll}
                                            setIdsInLoop={this.setIdsInLoop}
                                            toggleModal={this.toggleModal}
                                            user={this.props.userAccount.user}
                                            ads={this.props.ads}
                                            sections={sections}
                                        />
                                    </Layout>
                                </Route>
                                <Route exact path="/bookmarks">
                                    <Layout
                                        loggedIn={this.state.loggedIn}
                                        user={user}
                                        sections={sections}
                                        categories={categories}
                                        changeSection={this.changeSection}
                                        filterNews={this.filterNews}
                                        ads={this.props.ads}
                                        applySearch={this.applySearch}
                                        filterToggler={this.filterToggler}
                                        filterTab={this.state.filterTab}
                                        socialLinks={this.props.socialLinks}
                                        userLogout={this.props.userLogout}
                                        bookmarks={this.props.bookmarks.data}
                                        toggleModal={this.toggleModal}
                                        colorscheme={this.state.colorscheme}
                                    >
                                        <Bookmarks
                                            flag={this.state.flag}
                                            section={this.state.section}
                                            searchByFilter={this.state.searchByFilter}
                                            searchFilterParams={this.state.searchFilterParams}
                                            resetFilterFlag={this.resetFilterFlag}
                                            loggedIn={this.state.loggedIn}
                                            scroll={this.state.scroll}
                                            setIdsInLoop={this.setIdsInLoop}
                                            toggleModal={this.toggleModal}
                                            user={this.props.userAccount.user}
                                            ads={this.props.ads}
                                            changeSection={this.changeSection}
                                        />
                                    </Layout>
                                </Route>
                                <Route exact path="/article/:id">
                                    <Layout
                                        loggedIn={this.state.loggedIn}
                                        user={user}
                                        sections={sections}
                                        categories={categories}
                                        changeSection={this.changeSection}
                                        filterNews={this.filterNews}
                                        ads={this.props.ads}
                                        applySearch={this.applySearch}
                                        filterToggler={this.filterToggler}
                                        filterTab={this.state.filterTab}
                                        socialLinks={this.props.socialLinks}
                                        userLogout={this.props.userLogout}
                                        bookmarks={this.props.bookmarks.data}
                                        toggleModal={this.toggleModal}
                                        colorscheme={this.state.colorscheme}
                                    >
                                        <Article
                                            searchByFilter={this.state.searchByFilter}
                                            ads={this.props.ads}
                                            changeSection={this.changeSection}
                                            loggedIn={this.state.loggedIn}
                                            backToNews={this.backToNews}
                                            loopIds={this.state.loopIds}
                                            user={this.props.userAccount.user}
                                            colorscheme={this.state.colorscheme}/>
                                    </Layout>
                                </Route>
                                <Route exact path="/subscribe">
                                    <Layout
                                        loggedIn={this.state.loggedIn}
                                        user={user}
                                        sections={sections}
                                        categories={categories}
                                        changeSection={this.changeSection}
                                        filterNews={this.filterNews}
                                        ads={this.props.ads}
                                        applySearch={this.applySearch}
                                        filterToggler={this.filterToggler}
                                        filterTab={this.state.filterTab}
                                        socialLinks={this.props.socialLinks}
                                        userLogout={this.props.userLogout}
                                        bookmarks={this.props.bookmarks.data}
                                        toggleModal={this.toggleModal}
                                        colorscheme={this.state.colorscheme}
                                    >
                                        <Subscribe
                                            selectPlan={this.selectPlan}
                                            toggleModal={this.toggleModal}
                                            changeSection={this.changeSection}
                                            setDataToUpgrade={this.setDataToUpgrade}
                                            loggedIn={this.state.loggedIn}
                                        />
                                    </Layout>
                                </Route>
                                <Route exact path="/plan">
                                    <Layout
                                        loggedIn={this.state.loggedIn}
                                        user={user}
                                        sections={sections}
                                        categories={categories}
                                        changeSection={this.changeSection}
                                        filterNews={this.filterNews}
                                        ads={this.props.ads}
                                        applySearch={this.applySearch}
                                        filterToggler={this.filterToggler}
                                        filterTab={this.state.filterTab}
                                        socialLinks={this.props.socialLinks}
                                        userLogout={this.props.userLogout}
                                        bookmarks={this.props.bookmarks.data}
                                        toggleModal={this.toggleModal}
                                        colorscheme={this.state.colorscheme}
                                    >
                                        <Plan
                                            selectedPlan={this.state.selectedPlan}
                                            completePersonalDetails={this.completePersonalDetails}
                                            planTab={this.state.planTab}
                                            signupGoogle={this.signupGoogle}
                                            signupFb={this.signupFb}
                                            pageContents={this.props.pageContents.data}
                                            acceptTerms={this.acceptTerms}
                                            setPlanTab={this.setPlanTab}
                                            sendOTP={this.props.sendOTP}
                                            OTPSend={this.props.OTPSend}
                                            verifyOTP={this.props.verifyOTP}
                                            OTPverified={this.props.OTPverified}
                                            addSubscriber={this.props.addSubscriber}
                                            signupData={this.state.signupData}
                                            subscriber={this.props.subscriber}
                                            toggleModal={this.toggleModal}
                                            continueWithEmail={this.props.continueWithEmail}
                                            checkIfUserExists={this.checkIfUserExists}
                                            changeSection={this.changeSection}
                                            orderCallback={window.order ? JSON.parse(window.order) : {}}
                                        />
                                    </Layout>
                                </Route>

                                <Route exact path="/about-us">
                                    <Layout
                                        loggedIn={this.state.loggedIn}
                                        user={user}
                                        sections={sections}
                                        categories={categories}
                                        changeSection={this.changeSection}
                                        filterNews={this.filterNews}
                                        ads={this.props.ads}
                                        applySearch={this.applySearch}
                                        filterToggler={this.filterToggler}
                                        filterTab={this.state.filterTab}
                                        socialLinks={this.props.socialLinks}
                                        userLogout={this.props.userLogout}
                                        bookmarks={this.props.bookmarks.data}
                                        toggleModal={this.toggleModal}
                                        colorscheme={this.state.colorscheme}
                                    >
                                        <AboutUs
                                            pageContents={this.props.pageContents.data}
                                            changeSection={this.changeSection}/>
                                    </Layout>
                                </Route>
                                <Route exact path="/contact-us">
                                    <Layout
                                        loggedIn={this.state.loggedIn}
                                        user={user}
                                        sections={sections}
                                        categories={categories}
                                        changeSection={this.changeSection}
                                        filterNews={this.filterNews}
                                        ads={this.props.ads}
                                        applySearch={this.applySearch}
                                        filterToggler={this.filterToggler}
                                        filterTab={this.state.filterTab}
                                        socialLinks={this.props.socialLinks}
                                        userLogout={this.props.userLogout}
                                        bookmarks={this.props.bookmarks.data}
                                        toggleModal={this.toggleModal}
                                        colorscheme={this.state.colorscheme}
                                    >
                                        <ContactUs
                                            pageContents={this.props.pageContents.data}
                                            changeSection={this.changeSection}/>
                                    </Layout>
                                </Route>
                                <Route exact path="/terms-of-use">
                                    <Layout
                                        loggedIn={this.state.loggedIn}
                                        user={user}
                                        sections={sections}
                                        categories={categories}
                                        changeSection={this.changeSection}
                                        filterNews={this.filterNews}
                                        ads={this.props.ads}
                                        applySearch={this.applySearch}
                                        filterToggler={this.filterToggler}
                                        filterTab={this.state.filterTab}
                                        socialLinks={this.props.socialLinks}
                                        userLogout={this.props.userLogout}
                                        bookmarks={this.props.bookmarks.data}
                                        toggleModal={this.toggleModal}
                                        colorscheme={this.state.colorscheme}
                                    >
                                        <TermsOfUse
                                            pageContents={this.props.pageContents.data}
                                            changeSection={this.changeSection}/>
                                    </Layout>
                                </Route>
                                <Route exact path="/privacy-policy">
                                    <Layout
                                        loggedIn={this.state.loggedIn}
                                        user={user}
                                        sections={sections}
                                        categories={categories}
                                        changeSection={this.changeSection}
                                        filterNews={this.filterNews}
                                        ads={this.props.ads}
                                        applySearch={this.applySearch}
                                        filterToggler={this.filterToggler}
                                        filterTab={this.state.filterTab}
                                        socialLinks={this.props.socialLinks}
                                        userLogout={this.props.userLogout}
                                        bookmarks={this.props.bookmarks.data}
                                        toggleModal={this.toggleModal}
                                        colorscheme={this.state.colorscheme}
                                    >
                                        <PrivacyPolicy
                                            pageContents={this.props.pageContents.data}
                                            changeSection={this.changeSection}/>
                                    </Layout>
                                </Route>
                                <Route exact path="/my-account">
                                    <Layout
                                        loggedIn={this.state.loggedIn}
                                        user={user}
                                        sections={sections}
                                        categories={categories}
                                        changeSection={this.changeSection}
                                        filterNews={this.filterNews}
                                        ads={this.props.ads}
                                        applySearch={this.applySearch}
                                        filterToggler={this.filterToggler}
                                        filterTab={this.state.filterTab}
                                        socialLinks={this.props.socialLinks}
                                        userLogout={this.props.userLogout}
                                        bookmarks={this.props.bookmarks.data}
                                        toggleModal={this.toggleModal}
                                        colorscheme={this.state.colorscheme}

                                    >
                                        {this.state.loggedIn === true &&
                                        <MyAccount
                                            subscriber={this.props.subscriber}
                                            toggleModal={this.toggleModal}
                                            loggedIn={this.state.loggedIn}
                                            updateProfile={this.props.updateProfile}
                                            errorMessage={this.props.errorMessage}
                                            profileUpdate={this.props.profileUpdate}
                                            changeSection={this.changeSection}
                                            setDataToRenew={this.setDataToRenew}

                                        />
                                        }
                                    </Layout>
                                </Route>
                                <Route exact path="/sign-in">
                                    <FullPageLayout
                                        loggedIn={this.state.loggedIn}
                                        user={user}
                                        colorscheme={this.state.colorscheme}
                                    >
                                        <SignIn
                                            loginGoogle={this.loginGoogle}
                                            loginFb={this.loginFb}
                                            loginEmail={this.loginEmail}
                                            errorMessage={this.props.errorMessage}/>
                                    </FullPageLayout>
                                </Route>
                                <Route exact path="/forgot-password">
                                    <FullPageLayout
                                        loggedIn={this.state.loggedIn}
                                        user={user}
                                        colorscheme={this.state.colorscheme}
                                    >
                                        <ForgotPassword/>
                                    </FullPageLayout>
                                </Route>
                                <Route exact path="/reset-password/:id/:id">
                                    <FullPageLayout
                                        loggedIn={this.state.loggedIn}
                                        user={user}
                                        colorscheme={this.state.colorscheme}
                                    >
                                        <ResetPassword/>
                                    </FullPageLayout>
                                </Route>
                                <Route component={NotFound} colorscheme={this.state.colorscheme}/>
                            </Switch>
                        </Router>
                    </React.Fragment>
                }
                <OhariModal
                    showModal={this.state.showModal}
                    toggleModal={this.toggleModal}
                    modalHeader={this.state.modalHeader}
                    modalMessage={this.state.modalMessage}
                    modalOKaction={this.state.modalOKaction}
                    modalShowCancel={this.state.modalShowCancel}
                    modalCancelAction={this.state.modalCancelAction}
                    modalSize={this.state.modalSize}
                    colorscheme={this.state.colorscheme}
                />

            </React.Fragment>

        )
    }

}
function mapStateToProps(state) {
    return {
        userAccount: actionReducers.getUserAccount(state),
        errorMessage: actionReducers.getErrorMessage(state),
        loader: actionReducers.getLoader(state),
        sections: actionReducers.getSections(state),
        categories: actionReducers.getCategories(state),
        pageContents: actionReducers.getPageContents(state),
        ads: actionReducers.getAds(state),
        socialLinks: actionReducers.getSocialLinks(state),
        OTPSend: actionReducers.getOTPSend(state),
        OTPverified: actionReducers.getOTPverified(state),
        subscriber: actionReducers.getSubscriber(state),
        profileUpdate: actionReducers.getProfileUpdate(state),
        continueWithEmail: actionReducers.getContinueWithEmail(state),
        bookmarks: actionReducers.getBookmarks(state),
    }
}
export default connect(mapStateToProps, {
    signUp: ActionCreators.doSignup,
    socialLogin: ActionCreators.doSocialLogin,
    userLogin: ActionCreators.userLogin,
    userLogout: ActionCreators.doLogout,
    findSections: ActionCreators.findSections,
    findCategories: ActionCreators.findCategories,
    findPageContents: ActionCreators.findPageContents,
    findStories: ActionCreators.findStories,
    findAds: ActionCreators.findAds,
    findSocialLinks: ActionCreators.findSocialLinks,
    sendOTP: ActionCreators.sendOTP,
    verifyOTP: ActionCreators.verifyOTP,
    addSubscriber: ActionCreators.addSubscriber,
    updateProfile: ActionCreators.updateProfile,
    checkSubscriberExists: ActionCreators.checkSubscriberExists,
    findBookmarks: ActionCreators.findBookmarks,
    clearErrorMessage: ActionCreators.clearErrorMessage,
    getOhariDetails: ActionCreators.getOhariDetails
})(App);
